import React, { useContext, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';

import { AnimatePresence, motion } from 'framer-motion';
import ExpandingComponent from './ExpandingComponent';
import Typewriter from './Typewriter';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from './context/LanguageContext';
import ContactForm from './ContactForm';

function Contact() {


    const { t } = useTranslation();
    const { language, setLanguage } = useContext(LanguageContext);

  return (
    <motion.main
    className="main__container flex items-center justify-center w-full h-[100vh] p-5 pt-20 lg:pt-5 overflow-y-auto "
    initial={{ opacity: 0 }}
     animate={{ opacity: "100%" }}
     exit={{ opacity: 0 }}
     transition={{ duration: 0.75 }}
 >
    <div className='grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 w-full h-full md:pr-[5vw] md:pl-[5vw] pt-[10vh] text-2xl'>
        <div className='h-full w-full grid grid-rows-3 grid-cols-1'>
            <p className='text-3xl font-bold'>{t('mycontacts')}</p>
            <div className='grid grid-cols-1 w-full h-max row-span-2 gap-5'>
                <div className='w-full h-full flex justify-center text-[1.5rem] md:text-2xl'>
                    <p className='font-bold pr-2'>E-mail: </p>
                    <p>contact@rifai.works</p>
                </div>
                <div className='w-full h-full flex justify-center text-[1.5rem] md:text-2xl'>
                    <p className='font-bold pr-2'>{t('phonenumber')}: </p>
                    <p>+46735895014</p>
                </div>

            </div>
        </div>
        <ContactForm/>
    </div>
    </motion.main>
  );
}

export default Contact;
