import React, { useEffect, useRef, useState } from 'react';
import logo from './logo.svg';
import './App.css';

import { AnimatePresence, motion } from 'framer-motion';
import ExpandingComponent from './ExpandingComponent';
import Typewriter from './Typewriter';
import useIntersectionObserver from './useIntersectionObserver';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function HomeSection2() {

    const { t } = useTranslation();

    const aborter = new AbortController;

    const [triggered, setTriggered] = useState(false);

    
    const [styles, setStyles] = useState(false);
    const [diverse, setDiverse] = useState(false);
    const [first, showFirst] = useState(false)


    const [skills, setSkills] = useState(false);
    const [useful, setUseful] = useState(false);
    const [second, showSecond] = useState(false)

    const [contact, setContact] = useState(false);
    const [ask, setAsk] = useState(false);
    const [third, showThird] = useState(false)
    
    const addScrollLock = () => {
        //@ts-ignore
        document.getElementById("homeMain").addEventListener('scroll', lockScroll, {signal: aborter.signal});
    }

    const lockScroll = () => {

            if(localStorage.getItem('presentation') != 'true'){
                            //@ts-ignore
        homeMain.scrollTo({
            top: window.outerHeight,
            behavior: 'smooth',
        })
    }
    }

    const unlockScroll = () => {
        //@ts-ignore
        aborter.abort()
}

    function show(){
        if(localStorage.getItem('presentation') != 'true'){
        setTimeout(function(){
            setStyles(true)
        }, 500)
    } else {
        const contactm = document.getElementById('contactcom')
        contactm?.classList.remove('hidden')
        showFirst(true)
        showThird(true)
        showSecond(true)
    }
    if(localStorage.getItem('presentation') != 'true'){
        const homeMain = document.getElementById('homeMain')
        //@ts-ignore
        homeMain.scrollTo({
            top: window.outerHeight,
            behavior: 'smooth',
        })
        //@ts-ignore
        addScrollLock()
    }
    }

    function showDiverse(){
        setTimeout(function(){
            setDiverse(true)
        }, 500)
    }

    function showUseful(){
        setTimeout(function(){
            setUseful(true)
        }, 500)

    }

    function showSkills(){
            setTimeout(function(){
                const skills1 = document.getElementById('skills1')
                const skills2 = document.getElementById('skills2')
                const contact1 = document.getElementById('contact1')
                const contact2 = document.getElementById('contact2')
                skills1?.classList.add('opacity-0')
                skills2?.classList.add('opacity-0')
                setTimeout(function(){
                    skills1?.classList.add('hidden')
                    skills2?.classList.add('hidden')
                    contact1?.classList.remove('hidden')
                    contact2?.classList.remove('hidden')
                    setContact(true)
                },1000)
            }, 500)
        showSecond(true)
    }

    function showAsk(){
        setTimeout(function(){
            setAsk(true)
        }, 500)

    }

    function showContact(){
        setTimeout(function(){
            const contact1 = document.getElementById('contact1')
            const contact2 = document.getElementById('contact2')
            const contactm = document.getElementById('contactcom')
            //@ts-ignore
            contact1.classList.add('opacity-0')
            //@ts-ignore
            contact2.classList.add('opacity-0')
            setTimeout(function(){
                contact1?.classList.add('hidden')
                contact2?.classList.add('hidden')
                contactm?.classList.remove('hidden')
                    showThird(true)
                    localStorage.setItem("presentation", "true")
                    //@ts-ignore
                    unlockScroll()
            }, 1000)
        }, 500)
    }


    function showStyles(){
        if (!triggered){
            setTriggered(true)
        const main = document.getElementById("appmain")
        setTimeout(function(){
            //@ts-ignore
            main.classList.add("text-black")
            //@ts-ignore
            main.classList.remove("scanlines")
            //@ts-ignore
            main.classList.remove("bg-gray-900")
            //@ts-ignore
            main.classList.add("bg-white")
            //@ts-ignore
            main.classList.remove("text-white")
        }, 500)
        setTimeout(function(){
         //@ts-ignore
         main.classList.remove("bg-white")
         //@ts-ignore
         main.classList.add("bg-emerald-200")
            //@ts-ignore
            main.classList.remove("text-black")
            //@ts-ignore
            main.classList.add("text-amber-800")
        }, 1600)
        setTimeout(function(){
            //@ts-ignore
            main.classList.remove("bg-emerald-200")
            //@ts-ignore
            main.classList.add("bg-black")
            //@ts-ignore
            main.classList.remove("text-amber-800")
            //@ts-ignore
            main.classList.add("text-white")
        }, 2700)
        setTimeout(function(){
            //@ts-ignore
            main.classList.remove("bg-black")
            //@ts-ignore
            main.classList.add("scanlines")
                        //@ts-ignore
            main.classList.add("bg-gray-900")
            //@ts-ignore
            main.classList.add("text-white")
            setTimeout(function(){
                const styles1 = document.getElementById('styles1')
                const styles2 = document.getElementById('styles2')
                const skills1 = document.getElementById('skills1')
                const skills2 = document.getElementById('skills2')

                styles1?.classList.add('opacity-0')
                styles2?.classList.add('opacity-0')
                showFirst(true)
                setTimeout(function(){
                    styles1?.classList.add('hidden')
                    styles2?.classList.add('hidden')
                    skills1?.classList.remove('hidden')
                    skills2?.classList.remove('hidden')

                    setSkills(true)
                }, 1000)
            }, 500)

        }, 3800)
    }
    }

    const ref = useIntersectionObserver({ onIntersect: show });
    
  return (
    <motion.main
    className="main__container flex items-center justify-center w-full h-[105vh] p-5 pt-20 lg:pt-5"
    initial={{ opacity: 0 }}
     animate={{ opacity: "100%" }}
     exit={{ opacity: 0 }}
     transition={{ duration: 0.75 }}
 >

    <div className='h-full w-full flex items-center justify-center'>
        <div className='grid grid-rows-3 grid-cols-1 lg:grid-cols-3 lg:grid-rows-1 h-full w-full'>
            <div className='flex items-center justify-center w-full h-full'>
                <ExpandingComponent triggerExpand={first}>
                <Link to="/projects"><div className='flex items-center justify-center h-full w-full' style={{backgroundImage: "url('./projects.jpg')", backgroundSize: "cover", backgroundPosition:"center"}}>
                    <div className='flex h-[25vh] w-[90vw] lg:h-[75vh] lg:w-[30vw] border border-2 border-cyan-500 justify-center items-center text-center overflow-hidden transition-all hover:text-cyan-200 hover:border-[10px]'>
                    <p className='flex text-6xl'>{t('projects')}</p>
                    </div>
                    </div></Link>
                </ExpandingComponent>
            </div>
            <div className='w-full h-full flex items-center justify-center'>
            <div id='contactcom' className='hidden flex items-center justify-center w-full h-full row-span-2'>
                <ExpandingComponent triggerExpand={third}>
                    <Link to="/contact"><div className='flex items-center justify-center h-full w-full' style={{backgroundImage: "url('./contact.jpg')", backgroundSize: "cover", backgroundPosition:"center"}}>
                    <div className='flex h-[25vh] w-[90vw] lg:h-[75vh] lg:w-[30vw] border border-2 border-cyan-500 justify-center items-center text-center overflow-hidden transition-all hover:text-cyan-200 hover:border-[10px]' style={{backdropFilter: "brightness(0.7)"}}>
                    <p className='flex text-6xl'>{t('contact')}</p>
                    </div>
                    </div></Link>
                </ExpandingComponent>
            </div>
        <div id='h10' className='h-[10%]'>
    <div ref={ref} className='h-full w-full flex items-center justify-center'>
        <div className='grid grid-cols-1 grid-rows-2 h-full w-full'>
            <div className='h-max w-full'>
                <div id='styles1' className='transition-all duration-1000'>
    <Typewriter text={t('mystyles')}
    trigger={styles}
    speed={100}
    className='text-3xl'
    onComplete={showDiverse}
    />
       </div>

       <div id='skills1' className='hidden transition-all duration-1000'>
    <Typewriter text={t('myskills')}
    trigger={skills}
    speed={100}
    className='text-3xl'
    onComplete={showUseful}
    />
       </div>
       <div id='contact1' className='hidden transition-all duration-1000'>
    <Typewriter text={t('mycontact')}
    trigger={contact}
    speed={100}
    className='text-3xl'
    onComplete={showAsk}
    />
       </div>


    <div className='h-max w-full'>
        <div id='styles2' className='transition-all duration-1000'>
        <Typewriter text={t('diverse')}
    trigger={diverse}
    speed={150}
    className='text-4xl md:text-6xl'
    onComplete={showStyles}
    />
    </div>
    <div id='skills2' className='hidden transition-all duration-1000'>
        <Typewriter text={t('useful')}
    trigger={useful}
    speed={150}
    className='text-4xl md:text-6xl'
    onComplete={showSkills}
    />
    </div>

    <div id='contact2' className='hidden transition-all duration-1000'>
        <Typewriter text={t('glad')}
    trigger={ask}
    speed={150}
    className='text-xl md:text-6xl'
    onComplete={showContact}
    />
    </div>

    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div className='flex items-center justify-center w-full h-full'>
                <ExpandingComponent triggerExpand={second} >
                <Link to="/services"><div className='flex items-center justify-center h-full w-full bg-white' style={{backgroundImage: "url('./services.jpg')", backgroundSize: "cover", backgroundPosition:"center"}}>
                    <div className='flex h-[25vh] w-[90vw] lg:h-[75vh] lg:w-[30vw] border border-2 border-cyan-500 justify-center items-center text-center overflow-hidden transition-all hover:text-cyan-200 hover:border-[10px]' style={{backdropFilter: "brightness(0.5)"}}>
                    <p className='flex text-6xl'>{t('services')}</p>
                    </div>
                    </div> </Link>
                </ExpandingComponent>
            </div>
    </div>
    </div>
    </motion.main>
  );
}

export default HomeSection2;
