import React, { useContext, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';

import { AnimatePresence, motion } from 'framer-motion';
import ExpandingComponent from './ExpandingComponent';
import Typewriter from './Typewriter';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from './context/LanguageContext';

function HomeSection1() {

    const [allExpanded, setAllExpanded] = useState(false);
    const [allTyped, setAllTyped] = useState(false);
    const [learnShown, setLearnShown] = useState(false);
    const [showAbdul, setShowAbdul] = useState(false);

    const { t } = useTranslation();
    const { language, setLanguage } = useContext(LanguageContext);

    function showlearn(){
        setLearnShown(true)
    }

    const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => { setLanguage(event.target.value); };

    function showMain(){
        setTimeout(function(){
            setAllTyped(true)
        }, 100)

    }

    useEffect(() => {
 
        setTimeout(function(){
            setAllExpanded(true)
            setTimeout(function(){
                setShowAbdul(true)
            }, 750)
        }, 100)
    })
  return (
    <motion.main
    className="main__container flex items-center justify-center w-full h-[80vh] md:h-screen p-5 pt-20 lg:pt-5"
    initial={{ opacity: 0 }}
     animate={{ opacity: "100%" }}
     exit={{ opacity: 0 }}
     transition={{ duration: 0.75 }}
 >
    <div className='h-max w-full flex items-center justify-center'>
    <div className='grid grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 w-full h-full transition-all duration-1000 lg:gap-4'>
    <ExpandingComponent triggerExpand={allExpanded}>
        <img className="w-max h-[30vh] md:h-[35vh] lg:h-[25vw] border transition-all duration-1000" src='./portrait.png'></img>
    </ExpandingComponent>
    <div className='flex items-center justify-center mb-10'>
    <div className='grid grid-cols-1 grid-rows-4 h-max'>
        <div className='flex items-end justify-center pb-20 h-[2vh]'>
        <Typewriter text={t('hi')}
    trigger={showAbdul}
    speed={30}
    className='text-md lg:text-4xl'
    onComplete={showMain}
    />
        </div>
    <div className='w-full h-full flex text-left items-start justify-left row-span-2'>
    <Typewriter text={t('homedesc')}
    trigger={allTyped}
    speed={25}
    className='text-md lg:text-2xl break-words'
    onComplete={showlearn}
    />
   </div>
   <div id='learnmore' className='pt-20 transition-all'>
    <div>
    <Typewriter text={t('learnmore')}
    trigger={learnShown}
    speed={50}
    className='text-md lg:text-3xl'
    />
    </div>
    <div>

    </div>
    </div>
   </div>
   </div>
   </div>
    </div>
    </motion.main>
  );
}

export default HomeSection1;
