import React, { useEffect, useState } from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Navbar() {


    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(function(){
            const div = document.getElementById("navbar")
            div?.classList.remove("opacity-0")
        }, 1500)
    })
  return (
<div id="navbar" className='hidden lg:visible lg:flex opacity-0 sticky w-full h-[5vh] grid grid-cols-2 transition-all'>
    <div className='w-full h-inherit flex items-center justify-center'>
    <Link to="/home"><img className="h-[5vh]" src='rifai.png'/></Link>
    </div>
    <div className='h-full w-full grid grid-cols-4 grid-rows-1 text-xl font-semibold'>
        <div className='w-full h-full flex justify-center items-center'>
            <Link className="" to={'/home'}><p className='hover:text-sky-300 cursor-pointer transition-all'>{t('home')}</p></Link>
            </div>
            <div className='w-full h-full flex justify-center items-center'>
            <Link className="" to={'/services'}><p className='hover:text-sky-300 cursor-pointer transition-all'>{t('services')}</p></Link>
            </div>
            <div className='w-full h-full flex justify-center items-center'>
            <Link className="" to={'/projects'}><p className='hover:text-sky-300 cursor-pointer transition-all'>{t('projects')}</p></Link>
            </div>
            <div className='w-full h-full flex justify-center items-center'>
            <Link className="" to={'/contact'}><p className='hover:text-sky-300 cursor-pointer transition-all'>{t('contact')}</p></Link>
            </div>
    </div>
</div>
  );
}

export default Navbar;
