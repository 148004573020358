import React, { useState, useEffect } from 'react';

type TypewriterProps = {
    text: string;
    trigger: boolean;
    speed?: number;
    className?: string;
    onComplete?: () => void; // New prop for the callback function
};

const Typewriter: React.FC<TypewriterProps> = ({ text, trigger, speed = 50, className, onComplete }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (trigger) {
            interval = setInterval(() => {
                setDisplayedText((prev) => prev + text[currentIndex]);
                setCurrentIndex((prev) => prev + 1);
            }, speed);
        }

        if (currentIndex >= text.length) {
            clearInterval(interval!);
            if (onComplete) {
                onComplete();
            }
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [trigger, currentIndex, text, speed, onComplete]);

    useEffect(() => {
        if (!trigger) {
            setDisplayedText('');
            setCurrentIndex(0);
        }
    }, [trigger]);

    return (
        <p className={`whitespace-pre-wrap ${className}`}>
            {displayedText}
        </p>
    );
};

export default Typewriter;
