import React, { useContext, useEffect, useState } from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import './App.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from './context/LanguageContext';
import ExpandingComponent from './ExpandingComponent';

function Startup() {
  const [progress, setProgress] = useState(0);
  const [languageSet, setLanguageSet] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language, setLanguage } = useContext(LanguageContext);
  const [shown, setShown] = useState(false);

  useEffect(() => {
      const interval = setInterval(() => {
        setProgress(prev => {
          if (prev >= 100) {
            clearInterval(interval); 
            if(localStorage.getItem('language') == 'none'){
            setShown(true)
            } else {
                navigate('/home')
            }
          }
          return prev + 1;
        });
      }, 15);
      return () => clearInterval(interval);
  }, [setLanguage, navigate]);

  const handleLanguageClick = (lang: string) => {
    setLanguage(lang)
    setLanguageSet(true);
    navigate('/home');
  };

  return (
    <div className='w-screen h-screen grid grid-cols-1 grid-rows-3 md:grid-rows-5'>
        <div className='hidden md:block'/>
      <div></div>
      <div className='flex items-center justify-center w-full h-full'>
        {shown ? (
          <div className='grid grid-rows-3 grid-cols-1 md:grid-cols-5 md:grid-rows-1 gap-2 md:gap-5 w-full h-full'>
            <div className='hidden md:block'/>
            <div 
              className='w-full h-full flex items-center justify-center cursor-pointer transition-all'
              onClick={() => handleLanguageClick('ro')}
            >
                <div className='w-full h-full' style={{backgroundImage: 'url("./ro.webp")', backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition: 'center'}}/>
            </div>
            <div 
              className='w-full h-full flex items-center justify-center cursor-pointer transition-all'
              onClick={() => handleLanguageClick('en')}
            >
                <div className='w-full h-full' style={{backgroundImage: 'url("./uk.webp")', backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition: 'center'}}/>
            </div>
            <div 
              className='w-full h-full flex items-center justify-center cursor-pointer transition-all'
              onClick={() => handleLanguageClick('sv')}
            >
              <div className='w-full h-full' style={{backgroundImage: 'url("./se.webp")', backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition: 'center'}}/>
            </div>
            <div className='hidden md:block'/>
            <div className='hidden md:block'/>
          </div>
        ) : (
          <div id="bar" className='flex items-center justify-center w-full h-full'>
            <ProgressBar 
              isLabelVisible={false} 
              transitionDuration='0' 
              width='50vw' 
              borderRadius='0' 
              bgColor='darkblue' 
              completed={progress}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Startup;
