import React, { useEffect, useState } from 'react';
import './App.css';
import { AnimatePresence, motion } from 'framer-motion';
import CircleButton from './CircleButton';
import { useTranslation } from 'react-i18next';
import ExpandingComponent from './ExpandingComponent';
import { useNavigate } from 'react-router-dom';

function Services() {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Initialize useNavigate
  // State for expanding and typing animations
  const [allExpanded, setAllExpanded] = useState<boolean>(false);
  const [allTyped, setAllTyped] = useState<boolean>(false);

  // State for modal visibility and content
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalCAD, setModalCAD] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalText, setModalText] = useState<string>('');
  const [modalPic, setModalPic] = useState<string>('');

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setAllExpanded(true);
      const timer2 = setTimeout(() => {
        setAllTyped(true);
      }, 750);
      return () => clearTimeout(timer2);
    }, 100);
    return () => clearTimeout(timer1);
  }, []);
  

  const handleButtonClick = (text: string) => {
    if (text === "webdev"){
      setModalTitle(t("webdev"))
      setModalText(t("webdevmodal"));
      setModalPic("url('./webmodal.jpg')");
      setModalCAD(false)
    } else if (text === "embedded"){
      setModalTitle(t("embedded"))
      setModalText(t("embeddedmodal"));
      setModalPic("url('./embeddedmodal.jpg')");
      setModalCAD(false)
    } else if (text === "android"){
      setModalTitle(t("android"))
      setModalText(t("androidmodal"));
      setModalPic("url('./androidmodal.jpg')");
      setModalCAD(false)
    } else if (text === "sysadmin"){
      setModalTitle(t("sysadmin"))
      setModalText(t("sysadminmodal"));
      setModalPic("url('./sysadminmodal.jpg')");
      setModalCAD(false)
    } else if (text === "3D CAD"){
      setModalTitle("3D CAD")
      setModalText(t("3dcadmodal"));
      setModalPic("url('./3dcadmodal.jpg')");
      setModalCAD(true)
    } else if (text === "2dcad"){
      setModalTitle(t("2dcad"))
      setModalText(t("2dcadmodal"));
      setModalPic("url('./2dcadmodal.jpg')");
      setModalCAD(true)
    } else if (text === "pcbdesign"){
      setModalTitle(t("pcbdesign"))
      setModalText(t("pcbdesignmodal"));
      setModalPic("url('./pcbdesignmodal.jpg')");
      setModalCAD(true)
    }
  
      setModalVisible(true);

      setTimeout(function(){
        const backg = document.getElementById("backgrounddiv")
        backg?.classList.remove("bg-opacity-0")
        backg?.classList.add("bg-opacity-50")
      }, 1)
      

      setTimeout(function(){
        const modaltxt = document.getElementById("modal")
        const modaltitle = document.getElementById("modaltitle")
        const closemodal = document.getElementById("closemodal")
        //@ts-ignore
        modaltxt.classList.remove("opacity-0")
        //@ts-ignore
        modaltitle.classList.remove("opacity-0")
        //@ts-ignore
        closemodal.classList.remove("opacity-0")

      
      }, 1000)
  };

  const closeModal = () => {
    setModalVisible(false);
    const backg = document.getElementById("backgrounddiv")
    backg?.classList.add("bg-opacity-0")
    backg?.classList.remove("bg-opacity-50")
      const modaltxt = document.getElementById("modal")
      const modaltitle = document.getElementById("modaltitle")
      const closemodal = document.getElementById("closemodal")
      //@ts-ignore
      modaltxt.classList.add("opacity-0")
      //@ts-ignore
      modaltitle.classList.add("opacity-0")
      //@ts-ignore
      closemodal.classList.add("opacity-0")
  };

  return (
    <motion.main
      id='services'
      className="main__container w-full h-screen p-5 overflow-y-scroll"
      initial={{ opacity: 0 }}
      animate={{ opacity: "100%" }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      <div className='gap-10 lg:gap-0 grid grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 w-full pt-20'>
        <div> 
          <p className='text-3xl text-blue-400'>Software</p>
          <div className='grid grid-cols-1 grid-rows-4 w-full h-full pt-10'>
            <CircleButton
              svgIconUrl={'./website.svg'}
              text={t("webdev")}
              bgColor="bg-blue-900"
              textColor="text-blue-300"
              borderColor="border-blue-500"
              onClick={() => handleButtonClick("webdev")} // Button click handler
            />
            <CircleButton
              svgIconUrl={'./avr.svg'}
              text={t("embedded")}
              bgColor="bg-blue-900"
              textColor="text-blue-300"
              borderColor="border-blue-500"
              onClick={() => handleButtonClick("embedded")}
            />
            <CircleButton
              svgIconUrl={'./android.svg'}
              text={t("android")}
              bgColor="bg-blue-900"
              textColor="text-blue-300"
              borderColor="border-blue-500"
              onClick={() => handleButtonClick("android")}
            />
            <CircleButton
              svgIconUrl={'./linux.svg'}
              text={t("sysadmin")}
              bgColor="bg-blue-900"
              textColor="text-blue-300"
              borderColor="border-blue-500"
              onClick={() => handleButtonClick("sysadmin")}
            />
          </div>
        </div>
        <div className='pt-20 lg:pt-0'>
          <p className='text-3xl text-red-400'>Design</p>
          <div className='grid grid-cols-1 grid-rows-4 w-full h-full gap-20 pt-20'>
            <CircleButton
              svgIconUrl={'./block.svg'}
              text="3D CAD"
              bgColor="bg-red-900"
              textColor="text-red-300"
              borderColor="border-red-500"
              onClick={() => handleButtonClick("3D CAD")}
            />
            <CircleButton
              svgIconUrl={'./blueprint.svg'}
              text={t("2dcad")}
              bgColor="bg-red-900"
              textColor="text-red-300"
              borderColor="border-red-500"
              onClick={() => handleButtonClick("2dcad")}
            />
            <CircleButton
              svgIconUrl={'./pcb.svg'}
              text={t("pcbdesign")}
              bgColor="bg-red-900"
              textColor="text-red-300"
              borderColor="border-red-500"
              onClick={() => handleButtonClick("pcbdesign")}
            />
          </div>
        </div>
      </div>
      <div className='h-[20vh]'></div>

      {/* Modal Overlay */}
      {modalVisible && (
        <div id="backgrounddiv" className="fixed inset-0 bg-black flex justify-center items-center z-50 transition-all duration-500 bg-opacity-0">
          <ExpandingComponent triggerExpand={modalVisible}>
          <div className={`${modalCAD === true ? 'bg-[#1f0d0d]' : 'bg-gray-900'} p-10 rounded-md relative h-[50vh] md:h-[90vh] text-center overflow-hidden max-w-[80vw]`}>
            <button 
            id='closemodal'
              className="opacity-0 absolute top-3 right-3 text-2xl text-red-500 font-bold duration-500"
              onClick={closeModal}
            >
              X
            </button>
            <div className='grid grid-cols-1 grid-rows-2 w-full h-full md:p-10 gap-[10vh] md:gap-[20vh] lg:gap-[30vh] xl:gap-[10vh] overflow-y-auto'>
              <div className='grid grid-cols-1 grid-rows-3 h-full w-full gap-[10vh] md:gap-0'>
                <p id='modaltitle' className='opacity-0 text-2xl font-bold duration-500'>{modalTitle}</p>
                <p id="modal" className='row-span-2 text-lg p-1 md:text-xl lg:leading-loose w-full h-full opacity-0 transition-all duration-500'>{modalText}</p>
              </div>
              <div className={`${modalCAD === true ? 'border-red-700' : 'border-cyan-500'} hidden md:flex items-center justify-center h-full w-full bg-white rounded-[5vh] border-2 `} style={{backgroundImage: modalPic, backgroundSize: "cover", backgroundPosition:"center"}}></div>
            </div>
          </div>
          </ExpandingComponent>
        </div>
      )}
    </motion.main>
  );
}

export default Services;
