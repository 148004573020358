import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { AnimatePresence, motion } from 'framer-motion';
import ExpandingComponent from './ExpandingComponent';
import Typewriter from './Typewriter';
import HomeSection1 from './HomeSection1';
import HomeSection2 from './HomeSection2';
import CircleButton from './CircleButton';
import { useTranslation } from 'react-i18next';
import { PowerGlitch } from 'powerglitch'
import ImageCard from './ImageCard';

function Projects() {

  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalCAD, setModalCAD] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalText, setModalText] = useState<string>('');
  const [modalPic, setModalPic] = useState<string>('');
  const [modalLink, setModalLink] = useState<string>('');
  const [modalLinkText, setModalLinkText] = useState<string>('');

    const handleImageClick = () => {
      console.log("Image clicked!");
    };

    const handleButtonClick = (text: string) => {
      if (text === "essexpress"){
        setModalTitle(t("essexpresstitle"))
        setModalText(t("essexpress"));
        setModalPic("url('./essexpress.png')");
        setModalCAD(false)
        setModalLink("https://essexpress.se")
        setModalLinkText(t("websitelink"))
      } else if (text === "binancespider"){
        setModalTitle(t("binancespidertitle"))
        setModalText(t("binancespider"));
        setModalPic("url('./spider.jpeg')");
        setModalCAD(false)
        setModalLink("https://github.com/rifai1302/binanceSpider")
        setModalLinkText(t("githublink"))
      }
    
        setModalVisible(true);
  
        setTimeout(function(){
          const backg = document.getElementById("backgrounddiv")
          backg?.classList.remove("bg-opacity-0")
          backg?.classList.add("bg-opacity-50")
        }, 1)
        
  
        setTimeout(function(){
          const modaltxt = document.getElementById("modal")
          const modaltitle = document.getElementById("modaltitle")
          const closemodal = document.getElementById("closemodal")
          //@ts-ignore
          modaltxt.classList.remove("opacity-0")
          //@ts-ignore
          modaltitle.classList.remove("opacity-0")
          //@ts-ignore
          closemodal.classList.remove("opacity-0")
        
        }, 1000)
    };
  
    const closeModal = () => {
      setModalVisible(false);
      const backg = document.getElementById("backgrounddiv")
      backg?.classList.add("bg-opacity-0")
      backg?.classList.remove("bg-opacity-50")
        const modaltxt = document.getElementById("modal")
        const modaltitle = document.getElementById("modaltitle")
        const closemodal = document.getElementById("closemodal")
        //@ts-ignore
        modaltxt.classList.add("opacity-0")
        //@ts-ignore
        modaltitle.classList.add("opacity-0")
        //@ts-ignore
        closemodal.classList.add("opacity-0")
    };

  return (
    <motion.main
    id='projects'
    className="main__container w-full h-screen overflow-y-scroll"
    initial={{ opacity: 0 }}
     animate={{ opacity: "100%" }}
     exit={{ opacity: 0 }}
     transition={{ duration: 0.75 }}
 >
  <div className='w-full h-full flex items-center justify-center pt-[15vh] lg:pt-[10vh]'>
  <div className='w-full h-full grid grid-cols-1 grid-rows-3 lg:grid-cols-3 lg:grid-rows-3 gap-5 pr-5 pl-5'>
  <div className='w-full h-full flex items-center justify-center h-full w-full'>
  <ImageCard
        imageSrc="url('./essexpress.png')"
        text={t("essexpresstitle")}
        onClick={() => handleButtonClick("essexpress")}
      />
  </div>
  <div className='w-full h-full flex items-center justify-center h-full w-full'>
  <ImageCard
        imageSrc="url('./spider.jpeg')"
        text={t("binancespidertitle")}
        onClick={() => handleButtonClick("binancespider")}
      />
  </div>
  <div className='w-full h-full flex items-center justify-center'>
   <p>More coming soon!</p>
  </div>
  </div>
  </div>
  {modalVisible && (
        <div id="backgrounddiv" className="fixed inset-0 bg-black flex justify-center items-center z-50 transition-all duration-500 bg-opacity-0">
        <ExpandingComponent triggerExpand={modalVisible}>
        <div className={`${modalCAD === true ? 'bg-[#1f0d0d]' : 'bg-gray-900'} p-10 rounded-md relative h-[50vh] md:h-[90vh] text-center overflow-hidden max-w-[80vw]`}>
          <button 
          id='closemodal'
            className="opacity-0 absolute top-3 right-3 text-2xl text-red-500 font-bold duration-500"
            onClick={closeModal}
          >
            X
          </button>
          <div className='grid grid-cols-1 grid-rows-2 w-full h-full md:p-10 gap-[10vh] md:gap-[20vh] lg:gap-[30vh] xl:gap-[10vh] overflow-y-auto'>
          <div className='grid grid-cols-1 grid-rows-3 h-full w-full gap-[15vh] md:gap-0'>
                <p id='modaltitle' className='opacity-0 text-2xl font-bold duration-500'>{modalTitle}</p>
                <p id="modal" className='row-span-2 text-lg p-1 md:text-2xl lg:leading-loose w-full h-full opacity-0 transition-all duration-500'>{modalText} {modalLinkText} <a className="underline hover:text-cyan-500" href={modalLink}>{t("here")}</a></p>
              </div>
              <div className={`${modalCAD === true ? 'border-red-700' : 'border-cyan-500'} hidden md:flex items-center justify-center h-full w-full bg-none rounded-[5vh] `} style={{backgroundImage: modalPic, backgroundSize: "contain", backgroundPosition:"center", backgroundRepeat: "no-repeat"}}></div>
            </div>
          </div>
          </ExpandingComponent>
        </div>
      )}
    </motion.main>
  );
}

export default Projects;
