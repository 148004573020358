import React, { useEffect, useRef } from "react";
import { PowerGlitch } from "powerglitch";

interface CircleButtonProps {
  svgIconUrl?: string; // URL for the SVG icon (from public folder)
  text?: string;
  bgColor?: string;
  textColor?: string;
  circleSize?: string;
  borderColor?: string;
  onClick?: () => void; // Add optional onClick prop
}

const CircleButton: React.FC<CircleButtonProps> = ({
  svgIconUrl,
  text = "Click Me",
  bgColor = "bg-blue-500",
  textColor = "text-white",
  circleSize = "w-36 h-36",
  borderColor = "border-red-500",
  onClick,
}) => {
  const glitchRef = useRef<HTMLDivElement | null>(null);
  
  useEffect(() => {
    if (glitchRef.current) {
      PowerGlitch.glitch(glitchRef.current, {
        playMode: "hover",  // Trigger glitch on hover
        createContainers: true,
        hideOverflow: false,
        timing: {
          duration: 2000,    // Duration of the glitch effect
          iterations: 10,     // How many times to play on each hover
        },
        glitchTimeSpan: {
          start: 0,        // When to start the glitch within the animation duration
          end: 1,          // When to end the glitch within the animation duration
        },
        shake: {
          velocity: 5,      // Shake intensity
          amplitudeX: 0.2,   // Horizontal shake
          amplitudeY: 0.2,   // Vertical shake
        },
        slice: {
          count: 6,          // Number of glitch slices
          velocity: 15,      // Slice speed
          minHeight: 0.02,   // Minimum height of slices
          maxHeight: 0.15,   // Maximum height of slices
          hueRotate: true,   // Enable color distortion
        },
        pulse: false,         // Disable pulsing
      });
    }
  }, []);
  return (
    <div className="flex flex-col items-center justify-center">
      {/* Circle with animated border */}
      <div onClick={onClick} ref={glitchRef} className={`relative flex items-center justify-center ${circleSize} ${bgColor} rounded-full cursor-pointer`}>
        <div 
          className={`absolute inset-0 border-2 border-t-transparent border-b-transparent rounded-full animate-spin-slow ${borderColor}`}
        ></div>

        {/* Display the SVG from public folder */}
        <div className="z-10">
          {svgIconUrl && (
            <img src={svgIconUrl} alt="Icon" className="h-16 w-16" />
          )}
        </div>
      </div>

      {/* Text below the circle */}
      <p className={`mt-2 ${textColor} text-lg`}>{text}</p>
    </div>
  );
};

export default CircleButton;
