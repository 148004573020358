import React, { useState, useRef, useEffect } from 'react';

type ExpandingComponentProps = {
    children: React.ReactNode;
    triggerExpand?: boolean;  // Allow external control of expansion
};

const ExpandingComponent: React.FC<ExpandingComponentProps> = ({ children, triggerExpand }) => {
    const [expanded, setExpanded] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const [contentHeight, setContentHeight] = useState(0);

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [contentRef.current?.scrollHeight]);

    useEffect(() => {
        if (triggerExpand !== undefined) {
            setExpanded(triggerExpand);
        }
    }, [triggerExpand]);

    return (
        <div className="overflow-hidden flex items-center justify-center">
            <div
                ref={contentRef}
                className={`transition-all ease-in-out ${expanded ? 'max-h-[2000px] max-w-full' : 'max-w-[1px] max-h-0'}`}
                style={{
                    maxHeight: expanded ? `${contentHeight}px` : '0',
                    transition: expanded 
                        ? 'max-height 0.5s ease, max-width 0.5s ease 0.5s'
                        : 'max-width 0.5s ease, max-height 0.5s ease 0.5s'
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default ExpandingComponent;
