import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface SandwichButtonProps {
  onClick?: () => void;
}


const SandwichButton: React.FC<SandwichButtonProps> = ({ onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (onClick) onClick();
  };
  
  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  const { t } = useTranslation();

  return (
    <>
    <div className="lg:hidden flex items-center justify-center w-screen h-[5vh] pr-[5vw] pl-[5vw] pt-[5vw]">
    <div className="h-full w-full grid grid-cols-3 grid-rows-1">
      <div>
      <button
        onClick={handleToggle}
        className="flex items-center justify-center w-10 h-10 p-1 bg-gray-800 rounded focus:outline-none z-[100]"
        aria-label="Toggle Menu"
      >
        <div className="space-y-2">
          <span
            className={`block w-6 h-0.5 bg-white transition-transform duration-300 ${
              isOpen ? "transform rotate-45 translate-y-1.5" : ""
            }`}
          ></span>
          <span
            className={`block w-6 h-0.5 bg-white transition-opacity duration-300 ${
              isOpen ? "opacity-0" : "opacity-100"
            }`}
          ></span>
          <span
            className={`block w-6 h-0.5 bg-white transition-transform duration-300 ${
              isOpen ? "transform -rotate-45 -translate-y-1.5" : ""
            }`}
          ></span>
        </div>
      </button>

      {isOpen && (
        <nav className="absolute top-[10vh] left-[2vw] w-48 p-4 bg-gray-800 text-white rounded-lg shadow-lg z-[100]">
          <ul>
          <Link to="/home" onClick={handleCloseMenu}>
                    <li className="py-2 border-b border-gray-700 cursor-pointer">
                      {t("home")}
                    </li>
                  </Link>
                  <Link to="/services" onClick={handleCloseMenu}>
                    <li className="py-2 border-b border-gray-700 cursor-pointer">
                      {t("services")}
                    </li>
                  </Link>
                  <Link to="/projects" onClick={handleCloseMenu}>
                    <li className="py-2 border-b border-gray-700 cursor-pointer">
                      {t("projects")}
                    </li>
                  </Link>
                  <Link to="/contact" onClick={handleCloseMenu}>
                    <li className="py-2 cursor-pointer">{t("contact")}</li>
                  </Link>
          </ul>
        </nav>
      )}
      </div>
      <div className='w-full h-inherit flex items-center justify-center'>
      <Link to="/home" onClick={handleCloseMenu}><img className="h-[5vh]" src='rifai.png'/></Link>
    </div>
      </div>
      </div>
    </>
  );
};

export default SandwichButton;
