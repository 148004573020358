import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter,
  Route,
  Routes,
  Link,
  useLocation
} from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import Home from './Home';
import Startup from './Startup';
import Navbar from './Navbar';
import SandwichButton from './SandwichButton';
import Services from './Services';
import Projects from './Projects';
import Contact from './Contact';

function App() {
  return (
    <div className="App w-screen h-screen bg-gray-800 flex items-center justify-center">
      <div id="appmain" className='z-100 w-[99vw] h-[99vh] lg:w-[98vw] lg:h-[95vh] border border-cyan-400 scanlines rounded-3xl bg-gray-900 transition-all duration-1000 text-white'>
<AnimatePresence mode='wait'
      onExitComplete={() => {
        if (typeof window !== 'undefined') {
          window.scrollTo({ top: 0 })
        }
      }}>
        <BrowserRouter>
        <Navbar/>
        <SandwichButton/>
        <Routes>
                <Route path='/' element={<Startup />} />
                <Route path='/home' element={<Home />}/>
                <Route path='/services' element={<Services />}/>
                <Route path='/projects' element={<Projects />}/>
                <Route path='/contact' element={<Contact />}/>
        </Routes>
        </BrowserRouter>
        </AnimatePresence>
        </div>
    </div>
  );
}

export default App;
