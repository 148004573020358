import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from './context/LanguageContext';

interface FormData {
  name: string;
  email: string;
  message: string;
}


const ContactForm: React.FC = () => {

    const { t } = useTranslation();

  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState<FormData>({
    name: '',
    email: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    let errors: FormData = { name: '', email: '', message: '' };
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.email) errors.email = 'Email is required';
    if (!formData.message) errors.message = 'Message is required';
  
    if (errors.name || errors.email || errors.message) {
      setFormErrors(errors);
      return;
    }
  
    try {
      const response = await fetch('contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        setSubmitted(true); // Show success message
      } else {
        setSubmitted(true); // Show success message
        console.error('Failed to send message');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <div className="w-full max-w-lg h-max mx-auto p-8 bg-gray-800 rounded-lg shadow-md pb-[10vh] md:pb-8">
      <h2 className="text-2xl font-bold mb-6 text-center">{t('contactme')}</h2>
      {submitted && <p className="text-green-500 text-center mb-4">{t('thanks')}</p>}
      {!submitted &&
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex flex-col">
          <label htmlFor="name" className="text-sm font-semibold mb-2">
            {t('name')}
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleInputChange}
            className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 text-gray-800"
          />
          {formErrors.name && <p className="text-red-500 text-sm">{formErrors.name}</p>}
        </div>

        <div className="flex flex-col">
          <label htmlFor="email" className="text-sm font-semibold mb-2">
          {t('email')}
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleInputChange}
            className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 text-gray-800"
          />
          {formErrors.email && <p className="text-red-500 text-sm">{formErrors.email}</p>}
        </div>

        <div className="flex flex-col">
          <label htmlFor="message" className="text-sm font-semibold mb-2">
          {t('message')}
          </label>
          <textarea
            name="message"
            id="message"
            value={formData.message}
            onChange={handleInputChange}
            className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 h-32 resize-none text-gray-800"
          />
          {formErrors.message && <p className="text-red-500 text-sm">{formErrors.message}</p>}
        </div>

        <button
          type="submit"
          className="w-full py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 focus:outline-none text-gray-800"
        >
           {t('submit')}
        </button>
      </form>
      }
    </div>
  );
};

export default ContactForm;
