import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationRO from './locales/ro/translation.json';
import translationSV from './locales/sv/translation.json';

// The translations
const resources = {
  en: {
    translation: translationEN
  },
  ro: {
    translation: translationRO
  },
  sv: {
    translation: translationSV
  }
};


i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
