import { useEffect, useRef } from 'react';

type UseIntersectionObserverProps = {
    onIntersect: () => void;
    threshold?: number;
    root?: Element | null;
    rootMargin?: string;
};

const useIntersectionObserver = ({
    onIntersect,
    threshold = 0.1,
    root = null,
    rootMargin = '0px'
}: UseIntersectionObserverProps) => {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        onIntersect();
                    }
                });
            },
            {
                threshold,
                root,
                rootMargin,
            }
        );

        const currentRef = ref.current;
        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [onIntersect, threshold, root, rootMargin]);

    return ref;
};

export default useIntersectionObserver;
