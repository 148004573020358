import React from "react";

type ImageCardProps = {
  imageSrc: string;
  text: string;
  onClick: () => void;
};

const ImageCard: React.FC<ImageCardProps> = ({ imageSrc, text, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="w-full h-full relative border-4 border-cyan-500 hover:border-green-500 hover:rounded-xl transition-all duration-300 cursor-pointer"
    >
      <div style={{backgroundImage: imageSrc, backgroundSize: 'cover', backgroundPosition: 'center'}} className="w-full h-full" />

      {/* Text overlay at the bottom */}
      <div className="absolute bottom-0 w-full bg-black bg-opacity-50 text-white text-center py-2 font-semibold">
        {text}
      </div>
    </div>
  );
};

export default ImageCard;
