import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';

import { AnimatePresence, motion } from 'framer-motion';
import ExpandingComponent from './ExpandingComponent';
import Typewriter from './Typewriter';
import HomeSection1 from './HomeSection1';
import HomeSection2 from './HomeSection2';

function Home() {

    const [allExpanded, setAllExpanded] = useState(false);
    const [allTyped, setAllTyped] = useState(false);

    useEffect(() => {
        setTimeout(function(){
            setAllExpanded(true)
            setTimeout(function(){
                setAllTyped(true)
            }, 750)
        }, 100)
    })
  return (
    <motion.main
    id='homeMain'
    className="main__container w-full h-full p-5 overflow-y-scroll"
    initial={{ opacity: 0 }}
     animate={{ opacity: "100%" }}
     exit={{ opacity: 0 }}
     transition={{ duration: 0.75 }}
 >
    <HomeSection1/>
    <HomeSection2/>
    </motion.main>
  );
}

export default Home;
